import { useEffect, useMemo } from 'react';

import { useQuery } from '@tanstack/react-query';
import { useTripFilterContext } from '@vakantiesnl/services/src/context/useTripFilterContext';
import { useAccoOfferStore } from '@vakantiesnl/services/src/stores/accommodationStore';
import { getMinutesInMs } from '@vakantiesnl/services/src/util';
import { fetchHandler } from '@vakantiesnl/services/src/util/fetchHandler';
import { fetchVakNL } from '@vakantiesnl/services/src/util/vaknl-fetch';
import { Search, Theme } from '@vakantiesnl/types';

import { AccommodationRequestBody, mapAccoOnlyOffer } from './utils';
import { useGetAccommodationByPath } from '../useGetAccommodationByPath';

function getCheapestOfferPerDayQueryKey(args: AccommodationRequestBody, entityId?: number): string[] {
	return [`accommodation-cheapest-offers-${entityId}`, JSON.stringify(args)];
}

export function useGetCheapestOfferPerDay(): {
	cheapestOfferPerDayData: Search.AccoOnlyOffersResponseModel | undefined;
	isLoadingCheapestOfferPerDay: boolean;
} {
	const { accommodation } = useGetAccommodationByPath();
	const entityId = accommodation.heliosId;

	const {
		state: tripFiltersState,
		computed: { durations },
	} = useTripFilterContext();

	const setAccoOnlyDepartureDateOffer = useAccoOfferStore((s) => s.setAccoOnlyDepartureDateOffer);
	const accoOnlyDepartureDateOffer = useAccoOfferStore((s) => s.accoOnlyDepartureDateOffer);
	const setIsDepartureDateLoading = useAccoOfferStore((s) => s.setIsDepartureDateLoading);
	const isDepartureDateLoading = useAccoOfferStore((s) => s.isDepartureDateLoading);

	const requestBody: AccommodationRequestBody = {
		pre_offset: 15,
		post_offset: 16,
		brand: Theme.zvrnl,
		...(tripFiltersState.departureDate ? { departure_date: tripFiltersState.departureDate[0] } : {}),
		durations,
		party: tripFiltersState.party[0],
	};

	const query = useQuery({
		queryKey: getCheapestOfferPerDayQueryKey(requestBody, entityId),
		staleTime: getMinutesInMs(15),
		queryFn: () => fetchGetCheapestOfferPerDay(requestBody, entityId),
		enabled: !!entityId,
	});

	/** Selected date is first the selected price bar date else it's the selected departure date from the calendar */
	const selectedOfferDate = tripFiltersState.selected.date || tripFiltersState.departureDate?.[0];

	/**
	 * Selected offer
	 * @when a selected date is provided, find an offer with the date
	 * or match the cheapest offer
	 */
	const selectedOffer = useMemo(() => {
		return selectedOfferDate
			? query.data?.accommodations.offers.find(
					({ departureDateYYYYMMDD }) => departureDateYYYYMMDD === selectedOfferDate,
				)
			: query.data?.cheapestOffer;

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [query.data?.accommodations.offers]);

	useEffect(() => {
		if (selectedOffer !== accoOnlyDepartureDateOffer) {
			setAccoOnlyDepartureDateOffer(selectedOffer);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedOffer?.id]);

	useEffect(() => {
		if (query.isFetching !== isDepartureDateLoading) {
			setIsDepartureDateLoading(query.isFetching);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [query.isFetching]);

	return {
		cheapestOfferPerDayData: query.data,
		isLoadingCheapestOfferPerDay: query.isFetching,
	};
}

const createGetCheapestOfferPerDay = (body: AccommodationRequestBody, entityId: number): RequestInfo => {
	return new Request(
		`${process.env.NEXT_PUBLIC_SEARCH_ENDPOINT_URL}/api/v1/accommodation/${entityId}/acco-only/cheapest_offer_per_day`,
		{
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify(body),
		},
	);
};

async function fetchGetCheapestOfferPerDay(
	body: AccommodationRequestBody,
	entityId?: number,
): Promise<Search.AccoOnlyOffersResponseModel | undefined> {
	if (!entityId) return undefined;

	const rawCheapestOfferPerDay = await fetchHandler<Search.RawAccoOnlyOffersResponseModel>({
		fetchFn: () => fetchVakNL({ input: createGetCheapestOfferPerDay(body, entityId) }),
		errorMessage: `Something went wrong during fetching cheapest offers per day for entityId ${entityId}`,
	});

	const mappedOffers = rawCheapestOfferPerDay.accommodations[0].offers?.map(mapAccoOnlyOffer);

	const prices = mappedOffers.map(({ priceTotal }) => priceTotal);
	const [minPrice, maxPrice] = [Math.min(...prices), Math.max(...prices)];

	/** Select the lowest price, as a fall back (should never happen but ts asked me to) use the first offer */
	const cheapestOffer = mappedOffers.find(({ priceTotal }) => minPrice === priceTotal) ?? mappedOffers[0];

	return {
		party: rawCheapestOfferPerDay.party,
		brand: rawCheapestOfferPerDay.brand,
		currency: rawCheapestOfferPerDay.currency,
		resultsTotal: rawCheapestOfferPerDay.results_total,
		accommodations: {
			offers: mappedOffers,
		},
		minPrice,
		maxPrice,
		cheapestOffer,
	};
}
